<template>
  <div class="salesman">
    <div class="title">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="add(false)" type="primary">新增</el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from.fullname"
            placeholder="请输入销售姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.mobile"
            placeholder="请输入销售电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
            <el-select
            v-model="from.state"
            clearable
            placeholder="请选择状态"
          >
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div>
            统计范围：
          </div>
            <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="统计范围起"
            end-placeholder="统计范围止"
          >
          </el-date-picker>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="fullname" align="center" label="销售姓名">
        
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="销售电话">
        </el-table-column>
        <el-table-column
          prop="create_date"
          align="center"
          label="添加时间"
        >
        </el-table-column>
        <el-table-column
          prop="first_customer_num"
          align="center"
          label="新开客数量"
        >
        </el-table-column>
        <el-table-column
          prop="order_qty_num"
          align="center"
          label="销量(件)"
        >
        </el-table-column>
        <el-table-column
          prop="creator_num"
          align="center"
          label="建档客户"
        >
        </el-table-column>
        <el-table-column
          prop="bind_member_num"
          align="center"
          label="绑定客户"
        >
        </el-table-column>
        <el-table-column
          prop="remarks"
          align="center"
          label="备注"
        >
        </el-table-column>
        <el-table-column
          prop="state_txt"
          align="center"
          label="状态"
        >
       
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="add(scope.row)">编辑</el-button>
            <el-button type="text" @click="goputh(scope.row)">批量绑定</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <AddSales @getList="getList" ref="AddSales"></AddSales>
  </div>
</template>
      <script>
import { postExportsalelistexport } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate } from "@/utils/tools/date.js";
  import AddSales from "./modules/AddSales.vue";
export default {
  name: "salesman",
  components: {AddSales},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      pay_at: [formatDate(), formatDate()],
      from: {
        start_date: formatDate(), //	string	开始日期
        end_date: formatDate(), //	string	结束日期
        state: "", //	number	状态 1启用 2禁用
        fullname: "", //	string	用户名
        mobile: "", //	string	用户手机号
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
      this.hqlist();
  
  },
  methods: {
    payChange(val) {
        if (val) {
        this.from.start_date = val[0];
        this.from.end_date = val[1];
      } else {
        this.pay_at=[formatDate(),formatDate()];
        this.from.start_date = formatDate();
        this.from.end_date = formatDate();
      }
    },
    goputh(){
      this.$router.push({
                  path: "/memberlist",
                });
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportsalelistexport(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    add(e) {
      this.$refs.AddSales.hqinfo(e ? "编辑" : "添加", e ? e : "");
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.SalesP
        .salelist(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.pay_at=[formatDate(), formatDate()]
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
      <style lang="scss">
.salesman {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        display: flex;
        align-items: center;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
      
    