var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddSales" },
    [
      _vm.tableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: _vm.title,
                width: "50%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.tableVisible = false
                              },
                            },
                          },
                          [_vm._v("返回")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.bntloading },
                            on: { click: _vm.determine },
                          },
                          [_vm._v("确定")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                911678050
              ),
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售姓名", prop: "fullname" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入销售姓名" },
                            model: {
                              value: _vm.formData.fullname,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "fullname", $$v)
                              },
                              expression: "formData.fullname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售电话", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入销售电话" },
                            model: {
                              value: _vm.formData.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "mobile", $$v)
                              },
                              expression: "formData.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remarks" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: "请输入备注",
                            },
                            model: {
                              value: _vm.formData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "remarks", $$v)
                              },
                              expression: "formData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "state" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formData.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "state", $$v)
                                },
                                expression: "formData.state",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("启用"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("禁用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }